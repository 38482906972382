<template>
  <v-container fill-height>
    <v-row justify="center" align="center" dense>
      <v-col cols="12" v-if="alerts">
        <v-alert type="error">{{ alerts }}</v-alert>
      </v-col>
      <v-col cols="12" v-if="infoStatus">
        <v-alert type="info">资料状态: {{ infoStatus }}</v-alert>
      </v-col>

      <v-col
        cols="12"
        v-if="$store.state.currentUser.role == 'admin' || $store.state.currentUser.role == 'owner'"
      >
        <v-card>
          <v-card-text>
            <v-card-actions class="justify-center" v-if="waiting">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-actions>
            <v-card-actions class="justify-center" v-else>
              <v-btn type="submit" color="primary" @click="handlePrint()"> 打印 </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card v-if="infoStatus == 'submitted'">
          <v-card-text>
            <v-data-table
              :headers="gradeHeaders"
              :items="currentGrades"
              hide-default-footer
              disable-pagination
              light
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>当前评分/Current Grades</v-toolbar-title>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-text v-if="$store.state.currentUser.role == 'professor'">
            <v-card-actions class="justify-center">
              <v-btn dark color="primary" @click="handleGradeCamp()">评分/Grade</v-btn>
              <v-btn dark color="grey" to="/review/grade">返回列表/Return to List</v-btn>
            </v-card-actions>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  fixed
                  right
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="handleGradeCamp()"
                >
                  <v-icon>mdi-comment-eye</v-icon>
                </v-btn>
              </template>
              <span>评分/Grade</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  fixed
                  right
                  class="mt-16"
                  v-bind="attrs"
                  v-on="on"
                  color="grey"
                  to="/review/grade"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span> 返回列表/Return to List</span>
            </v-tooltip>

            <v-card-actions class="justify-center">
              <v-dialog persistent v-model="gradedDialog" max-width="500px">
                <v-card>
                  <v-card-title>评分/Grade</v-card-title>
                  <v-card-subtitle
                    >{{ userCampInfo.name_cn }}({{ userCampInfo.university_cn }})
                  </v-card-subtitle>
                  <v-card-text>
                    <v-alert color="info" dense text>
                      <h5>提示</h5>
                      <div class="text-caption">
                        90-100分（强烈推荐入营），80-89（推荐入营），60-79（不建议入营）
                      </div>
                      <v-divider class="my-2 info" style="opacity: 0.22"></v-divider>
                      <h5>NOTE</h5>
                      <div class="text-caption">
                        90-100（strongly recommended），80-89（recommended），60-79（not
                        recommended）
                      </div>
                    </v-alert>

                    <v-text-field
                      v-model.number="gradeScore"
                      :rules="rules.grade"
                      label="评分/Score (60-100)"
                      required
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn color="grey" @click="gradeClose">Cancel</v-btn>
                    <v-btn
                      color="primary"
                      :disabled="gradeScore <= 0 || gradeScore > 100"
                      @click="gradeSubmit"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card-text>

          <v-card-text
            v-if="
              $store.state.currentUser.role == 'admin' || $store.state.currentUser.role == 'owner'
            "
          >
            <v-card-actions class="justify-center">
              <v-btn color="primary" @click="handleReturnCamp()"> 退回资料 </v-btn>
              <v-dialog persistent v-model="returnCampDialog" max-width="640px">
                <v-card>
                  <v-card-title>确认退回资料/Confirm Send Back Camp Application</v-card-title>
                  <v-card-subtitle
                    >{{ userCampInfo.name_cn }}({{ userCampInfo.university_cn }})
                  </v-card-subtitle>
                  <v-card-text>
                    <v-alert type="warning">
                      退回资料会清除分组以及当前所有打分，确认操作吗？
                    </v-alert>
                    <v-textarea
                      v-model="returnReason"
                      :counter="1000"
                      auto-grow
                      outlined
                      label="原因 / Reason"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" dark @click="returnCampClose">Cancel</v-btn>
                    <v-btn
                      color="blue darken-1"
                      dark
                      @click="returnCampConfirm"
                      :disabled="!returnReason"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="userCampInfo && $store.state.currentUser">
        <v-card color="#385F73" dark>
          <v-card-title> 基本信息/Basic Info </v-card-title>
          <v-card-text>
            <v-list-item v-for="(desc, field) in campInfoFields.basic" :key="field">
              <v-list-item-title
                :class="userCampInfo[field] ? '' : 'red--text'"
                v-text="desc"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="field == 'gender' ? genderList[userCampInfo[field]] : userCampInfo[field]"
              ></v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title> 联系信息/Contact Info </v-card-title>
          <v-card-text>
            <v-list-item v-for="(desc, field) in campInfoFields.contact" :key="field">
              <v-list-item-title
                :class="userCampInfo[field] ? '' : 'red--text'"
                v-text="desc"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="(field == 'phone' ? '+86 ' : '') + userCampInfo[field]"
              ></v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>

        <v-card color="#385F73" dark>
          <v-card-title> 学校信息/University Info </v-card-title>
          <v-card-text>
            <v-list-item v-for="(desc, field) in campInfoFields.school" :key="field">
              <v-list-item-title
                :class="userCampInfo[field] || field == 'is_special_plan' ? '' : 'red--text'"
                v-text="desc"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo[field]"></v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title> 专业信息/Degree Info </v-card-title>
          <v-card-text>
            <v-list-item>
              <v-list-item-title
                :class="userCampInfo.major_cn ? '' : 'red--text'"
                v-text="campInfoFields.degree.major_cn"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.major_cn"></v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                :class="userCampInfo.major_en ? '' : 'red--text'"
                v-text="campInfoFields.degree.major_en"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.major_en"></v-list-item-subtitle>
            </v-list-item>

            <div v-for="item in degreeTypes" :key="item">
              <div v-if="userCampInfo['has_' + item + '_degree']">
                <v-list-item>
                  <v-list-item-title
                    v-text="campInfoFields.degree[item + '_degree_cn']"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="userCampInfo[item + '_degree_cn']"
                  ></v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    v-text="campInfoFields.degree[item + '_degree_en']"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="userCampInfo[item + '_degree_en']"
                  ></v-list-item-subtitle>
                </v-list-item>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card color="#385F73" dark>
          <v-card-title> 成绩信息/Ranking Info </v-card-title>
          <v-card-text>
            <v-list-item v-if="userCampInfo.overall_gpa_ranking">
              <v-list-item-title
                v-text="campInfoFields.ranking.overall_gpa_ranking"
                class="text-wrap"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.overall_gpa_ranking">
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="userCampInfo.major_gpa_ranking">
              <v-list-item-title
                v-text="campInfoFields.ranking.major_gpa_ranking"
                class="text-wrap"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.major_gpa_ranking"> </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title
                :class="userCampInfo.major_students ? '' : 'red--text'"
                v-text="campInfoFields.ranking.major_students"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.major_students"></v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title
                v-text="campInfoFields.ranking.special_notes"
                class="text-wrap"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="userCampInfo.special_notes"
                class="text-wrap"
              ></v-list-item-subtitle>
            </v-list-item>

            <v-data-table
              :headers="curriculumHeaders"
              :items="coreCurriculum"
              disable-pagination
              hide-default-footer
              light
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title
                    v-text="campInfoFields.curriculum.core_curriculum"
                  ></v-toolbar-title>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title :class="userCampInfo.personal_statement ? '' : 'red--text'">
            个人简述/Personal statement
          </v-card-title>
          <v-card-text>
            <pre v-bind:style="preStyle" v-text="userCampInfo.personal_statement"></pre>
          </v-card-text>
        </v-card>

        <v-dialog persistent v-model="uploadDialog" max-width="500px">
          <v-card>
            <v-card-title class="headline">替换文件/Replace File</v-card-title>
            <v-card-subtitle v-text="getUploadFieldDesc()"> </v-card-subtitle>

            <v-card-text>
              <v-file-input
                @change="selectFile"
                chips
                show-size
                counter
                :disabled="isUploading"
                :accept="fileTypes.doc"
              ></v-file-input>
            </v-card-text>
            <v-card-actions v-if="isUploading" class="justify-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-actions>
            <v-card-actions v-else class="justify-center">
              <v-btn color="grey" @click="uploadClose">Cancel</v-btn>
              <v-btn color="primary" :disabled="!uploadFile" @click="uploadSubmit">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card color="#385F73" dark>
          <v-card-title> 推荐信息/Recommend Info </v-card-title>
          <v-card-text>
            <div v-for="item in recommanderTypes" :key="item">
              <v-list-item>
                <v-list-item-title
                  v-text="campInfoFields.recommend['recommander_' + item + '_name']"
                  :class="
                    userCampInfo['recommander_' + item + '_name'] || item == 'c'
                      ? 'text-wrap'
                      : 'text-wrap red--text'
                  "
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="userCampInfo['recommander_' + item + '_name']"
                ></v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title
                  v-text="campInfoFields.recommend['recommander_' + item + '_phone']"
                  :class="
                    userCampInfo['recommander_' + item + '_phone'] || item == 'c'
                      ? 'text-wrap'
                      : 'text-wrap red--text'
                  "
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="userCampInfo['recommander_' + item + '_phone']"
                ></v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title
                  v-text="campInfoFields.recommend['recommander_' + item + '_email']"
                  :class="
                    userCampInfo['recommander_' + item + '_email'] || item == 'c'
                      ? 'text-wrap'
                      : 'text-wrap red--text'
                  "
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-text="userCampInfo['recommander_' + item + '_email']"
                ></v-list-item-subtitle>
              </v-list-item>

              <v-list-item v-if="userCampInfo['recommander_' + item + '_email']">
                <v-list-item-title
                  v-text="campInfoFields.recommend['recommander_' + item + '_letter']"
                >
                </v-list-item-title>

                <v-list-item-subtitle>
                  <v-tooltip top v-if="userCampInfo['recommander_' + item + '_letter']">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        @click="getFile(userCampInfo['recommander_' + item + '_letter'])"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>查看/View</span>
                  </v-tooltip>

                  <v-tooltip top v-if="userCampInfo['recommander_' + item + '_letter']">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          getFile(
                            userCampInfo['recommander_' + item + '_letter'],
                            `${userCampInfo.name_cn}-推荐信-${
                              userCampInfo['recommander_' + item + '_name']
                            }`
                          )
                        "
                      >
                        mdi-download
                      </v-icon>
                    </template>
                    <span>下载/Download</span>
                  </v-tooltip>

                  <v-tooltip top v-if="$store.state.currentUser.role == 'admin'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="handleUploadFile('recommander_' + item + '_letter')"
                      >
                        mdi-upload
                      </v-icon>
                    </template>
                    <span>上传/Upload</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item>

              <v-divider></v-divider>
            </div>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title> 上传材料/Upload </v-card-title>
          <v-card-text>
            <v-list-item chips v-for="(item, field) in campInfoFiles" :key="field">
              <v-list-item-title
                :class="
                  userCampInfo[field] || field == 'other_docs' ? 'text-wrap' : 'text-wrap red--text'
                "
                v-text="item.desc"
              ></v-list-item-title>

              <v-list-item-subtitle v-if="userCampInfo[field]">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="success"
                      v-bind="attrs"
                      v-on="on"
                      @click="getFile(userCampInfo[field])"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>查看/View</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="getFile(userCampInfo[field], `${userCampInfo.name_cn}-${item.short}`)"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>下载/Download</span>
                </v-tooltip>

                <v-tooltip top v-if="$store.state.currentUser.role == 'admin'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="handleUploadFile(field)">
                      mdi-upload
                    </v-icon>
                  </template>
                  <span>上传/Upload</span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>

        <v-card color="#385F73" dark>
          <v-card-title> 其他信息/Others </v-card-title>
          <v-card-text>
            <v-list-item v-for="(desc, field) in campInfoFields.other" :key="field">
              <v-list-item-title v-text="desc" class="text-wrap"></v-list-item-title>
              <v-list-item-subtitle>
                <pre v-bind:style="preStyle" v-text="userCampInfo[field]"></pre>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title
                :class="userCampInfo.access ? 'text-wrap' : 'text-wrap red--text'"
                v-text="campInfoFields.extra.access"
              ></v-list-item-title>
              <v-list-item-subtitle v-text="userCampInfo.access"></v-list-item-subtitle>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";
import consts from "@/consts";
import mimeDB from "mime-db";

export default {
  data: () => ({
    campID: 0,
    waiting: false,
    alerts: null,
    rules: consts.INPUT_RULES,
    campInfoFields: consts.CAMP_INFO,
    campInfoFiles: consts.CAMP_FILES,
    fileTypes: consts.FILE_TYPES,
    userCampInfo: null,
    infoStatus: "",
    returnReason: "",
    gradeScore: 0,
    currentGrades: [],
    coreCurriculum: [],
    degreeTypes: ["double", "second", "minor"],
    recommanderTypes: ["a", "b", "c"],
    genderList: {
      1: "男/Male",
      2: "女/Female",
    },
    curriculumHeaders: consts.CURRICULUM_HEADERS.slice(0, 5),
    gradeHeaders: consts.GRADE_HEADERS,
    gradedDialog: false,
    returnCampDialog: false,
    uploadDialog: false,
    isUploading: false,
    uploadFile: null,
    uploadField: "",
    preStyle: {
      "white-space": "pre-wrap",
      "word-break": "keep-all",
    },
  }),
  created() {
    this.campID = this.$route.params.id;
    this.getCampInfo();
  },
  computed: {},
  methods: {
    getCampInfo() {
      requests
        .get(`/api/review/camp/${this.campID}`)
        .then((res) => {
          const info = res.data.info;
          this.userCampInfo = info;
          this.infoStatus = res.data.status;
          this.currentGrades = res.data.grades;
          this.coreCurriculum = JSON.parse(info.core_curriculum);
        })
        .catch((err) => {
          this.alerts = err.response.data.detail;
        });
    },
    getFile(id, fname = null) {
      requests.get(`/api/users/files/${id}`, { responseType: "blob" }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        if (fname) {
          const ext = mimeDB[res.data.type].extensions[0];
          const title = fname + "." + ext;
          link.download = title;
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },

    getUploadFieldDesc() {
      if (this.uploadField == "") {
        return "";
      } else if (this.uploadField.startsWith("recommander_")) {
        return this.campInfoFields.recommend[this.uploadField];
      } else {
        return this.campInfoFiles[this.uploadField].desc;
      }
    },
    selectFile(file) {
      this.uploadFile = file;
    },
    handleUploadFile(field) {
      this.uploadField = field;
      this.uploadDialog = true;
    },
    uploadClose() {
      this.uploadDialog = false;
      this.$nextTick(() => {
        this.uploadField = "";
        this.uploadFile = null;
      });
    },
    uploadSubmit() {
      this.isUploading = true;
      const formData = new FormData();
      formData.append("file", this.uploadFile);
      formData.append("field", this.uploadField);
      requests
        .post(`/api/admin/camp/${this.campID}/files`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.isUploading = false;
          this.userCampInfo[this.uploadField] = res.data.file.id;
          this.uploadClose();
        })
        .catch((err) => {
          this.isUploading = false;
          alert(err.response.data.detail);
        });
    },

    handleGradeCamp() {
      this.gradedDialog = true;
    },
    gradeClose() {
      this.gradedDialog = false;
      this.$nextTick(() => {
        this.gradeScore = 0;
      });
    },
    gradeSubmit() {
      requests
        .post(`/api/review/grade`, { camp_id: this.campID, score: this.gradeScore })
        .then(() => {
          this.getCampInfo();
          this.gradeClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
    handleReturnCamp() {
      this.returnCampDialog = true;
    },
    returnCampClose() {
      this.returnCampDialog = false;
    },
    returnCampConfirm() {
      requests
        .post(`/api/admin/camp/${this.campID}/return`, { reason: this.returnReason })
        .then(() => {
          this.getCampInfo();
          this.returnCampClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
    forceFileDownload(res, title) {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
      const link = document.createElement("a");
      link.href = url;
      link.download = title;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handlePrint() {
      this.waiting = true;
      requests
        .get(`/api/admin/camp/${this.campID}/print`, { responseType: "blob" })
        .then((res) => {
          this.waiting = false;
          const title = `${this.userCampInfo.name_cn}-申请表-.pdf`;
          this.forceFileDownload(res, title);
        })
        .catch(() => {
          this.waiting = false;
          alert("生成 pdf 失败，请重试/failed to generate pdf, pls retry");
        });
    },
  },
};
</script>
